<template>
  <v-container fluid class="pa-0" style="height:100%;">
    <v-carousel
      class="card-content"
      id="card1"
      cycle
      hide-delimiter-background
      :show-arrows="false"
      interval="6000"
      :height="bannerHeigth"
    >
      <v-carousel-item src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/banner_bg.png">
        <v-card
          max-width="1200"
          :height="bannerHeigth"
          class="mx-auto px-4 white--text d-flex flex-column justify-center"
          flat
          style="background-color:transparent;"
        >
          <v-img
            class="flex-grow-0"
            width="650"
            src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/banner_headline_one%402x.png"
          ></v-img>
          <v-card-title class="white--text pl-0 pt-8">基于云计算和区块链技术</v-card-title>
          <v-card-title class="white--text pl-0 pt-0">集计量计重、防伪溯源、报告出证、大数据分析等丰富功能于一体</v-card-title>
        </v-card>
      </v-carousel-item>
      <v-carousel-item
        src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/banner_image_two%402x.png"
      >
        <v-card
          max-width="1200"
          :height="bannerHeigth"
          class="mx-auto white--text d-flex flex-column justify-center"
          flat
          style="background-color:transparent;"
        >
          <v-img
            width="693"
            class="flex-grow-0 mx-auto"
            src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/banner_image_two_headline%402x.png"
          ></v-img>
        </v-card>
      </v-carousel-item>
      <v-carousel-item
        src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/banner_image_three%402x.png"
      >
        <v-card
          max-width="1200"
          :height="bannerHeigth"
          class="mx-auto white--text d-flex flex-column justify-center"
          flat
          style="background-color:transparent;"
        >
          <v-img width="654" class="flex-grow-0 mx-auto" src="../assets/images/da_biao_ti@2x.png"></v-img>
        </v-card>
      </v-carousel-item>
    </v-carousel>
    <v-window id="card2" v-model="banner" style="position: relative;background-color:#FAF9FF;">
      <v-window-item eager v-for="banne in bannerArr" :key="banne.id">
        <v-card
          color="#FAF9FF"
          :height="bannerHeigth"
          max-width="1200"
          class="mx-auto white--text px-4"
          flat
        >
          <v-row
            justify="space-between"
            align="center"
            align-content="center"
            style="height: 100%;width: 100%;"
            no-gutters
          >
            <v-col sm="12" md="5" cols="12" class="text-center">
              <v-row class="flex-column ma-0 fill-height" justify="center">
                <v-col md="12" sm="12" cols="12" class="px-0">
                  <p
                    class="text-left font-weight-medium"
                    style="color: #1A80FF;font-size:70px;letter-spacing:6px !important;"
                  >{{banne.title}}</p>
                </v-col>
                <v-col md="12" sm="12" cols="12" class="px-0 black--text text-left title">
                  <!-- :style="{height:banne.titleHeight,}" -->
                  <p style="letter-spacing:1px !important;">{{banne.p}}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="0" md="7" cols="0" justify="right" align="right" class="d-none d-sm-flex">
              <v-img :width="banne.width" :height="banne.height" :src="banne.src"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-window-item>
      <div
        class="px-2"
        :style="{position: 'absolute',maxWidth: '1200px',height:bannerHeigth+'px',top:'65%',left:'0',right:'0',margin:'auto',padding:'0 16px'}"
      >
        <v-item-group v-model="banner" class="text-left">
          <v-item v-for="n in length" :key="`btn-${n}`" v-slot:default="{ active, toggle }">
            <v-btn small left icon @click="toggle" :color="active?'#1A80FF':'rgba(26,128,255,0.3)'">
              <v-icon small>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
      </div>
    </v-window>
    <v-card
      id="card3"
      tile
      :height="bannerHeigth"
      class="mx-auto white--text d-flex flex-column justify-center"
      flat
      img="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/four_characteristics_bg.png"
    >
      <v-img
        class="mx-auto flex-grow-0"
        width="1100"
        src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/core_bg.png"
      ></v-img>
    </v-card>

    <v-card
      color="#FAF9FF"
      id="card4"
      tile
      :height="bannerHeigth"
      flat
      class="d-flex flex-column justify-center"
    >
      <div class="mb-12" style="margin: 0 auto;">
        <v-img
          width="242"
          class="mx-auto my-12"
          src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/inspection_company%402x.png"
        ></v-img>
        <div class="d-flex justify-space-between align-center" style="width:100%">
          <v-img
            class="flex-shrink-1"
            min-width="50"
            max-width="100"
            style="cursor:pointer;"
            @click="$router.push('/registered')"
            src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/free_registration_icon%402x.png"
          ></v-img>
          <div class="flex-shrink-0">
            <v-item v-for="n in cardThreeLength" :key="`btn-${n}`" class="mx-auto">
              <v-btn icon x-small color="rgba(26,128,255,1)">
                <v-icon x-small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </div>
          <v-img
            class="flex-shrink-1"
            min-width="50"
            max-width="100"
            src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/platform_audit_icon%402x.png"
          ></v-img>
          <div class="flex-shrink-0">
            <v-item v-for="n in cardThreeLength" :key="`btn-${n}`" class="mx-auto">
              <v-btn icon x-small color="rgba(26,128,255,1)">
                <v-icon x-small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </div>
          <v-img
            class="flex-shrink-1"
            min-width="50"
            max-width="100"
            src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/start_using_icon%402x.png"
          ></v-img>
        </div>
        <div class="d-flex justify-space-between align-center" style="width:100%">
          <p class="headline" style="color:#1A80FF;">免费注册</p>
          <p class="headline" style="color:#1A80FF;">平台审核</p>
          <p class="headline" style="color:#1A80FF;">开始使用</p>
        </div>
        <!-- <v-row no-gutters justify="space-between">
          <v-col md="2" sm="2">
            <div style="cursor:pointer;" @click="$router.push('/registered')">
              <v-img
                width="100"
                class="mx-auto mb-6"
                src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/free_registration_icon%402x.png"
              ></v-img>
              <p class="headline" style="color:#1A80FF;">免费注册</p>
            </div>
          </v-col>
          <v-col cols="auto" style="height:100px;" class="d-flex align-center">
            <v-item v-for="n in length" :key="`btn-${n}`" class="mx-auto">
              <v-btn icon x-small color="rgba(26,128,255,1)">
                <v-icon x-small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-col>
          <v-col md="2" xs="2">
            <v-img
              width="100"
              class="mx-auto mb-6"
              src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/platform_audit_icon%402x.png"
            ></v-img>
            <p class="headline" style="color:#1A80FF;">平台审核</p>
          </v-col>
          <v-col cols="auto" style="height:100px;" class="d-flex align-center">
            <v-item v-for="n in length" :key="`btn-${n}`">
              <v-btn icon x-small color="rgba(26,128,255,1)">
                <v-icon x-small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-col>
          <v-col md="2" xs="2">
            <v-img
              width="100"
              class="mx-auto mb-6"
              src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/start_using_icon%402x.png"
            ></v-img>
            <p class="headline" style="color:#1A80FF;">开始使用</p>
          </v-col>
        </v-row>-->
      </div>
      <div class="mx-auto mt-12">
        <v-img
          width="211"
          class="mx-auto mb-12"
          src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/shipping_company%402x.png"
        ></v-img>

        <div class="d-flex justify-space-between align-center">
          <v-img
            class="flex-shrink-1"
            min-width="30"
            max-width="75"
            style="cursor:pointer;"
            src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/free_registration_icon%402x.png"
          ></v-img>
          <div class="flex-shrink-0">
            <v-item v-for="n in cardThreeLength" :key="`btn-${n}`" class="mx-auto">
              <v-btn icon x-small color="rgba(26,128,255,1)">
                <v-icon x-small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </div>
          <v-img
            class="flex-shrink-1"
            min-width="30"
            max-width="75"
            src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/upload_icon%402x.png"
          ></v-img>
          <div class="flex-shrink-0">
            <v-item v-for="n in cardThreeLength" :key="`btn-${n}`" class="mx-auto">
              <v-btn icon x-small color="rgba(26,128,255,1)">
                <v-icon x-small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </div>
          <v-img
            class="flex-shrink-1"
            min-width="30"
            max-width="75"
            src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/platform_audit_icon%402x.png"
          ></v-img>
          <div class="flex-shrink-0">
            <v-item v-for="n in cardThreeLength" :key="`btn-${n}`" class="mx-auto">
              <v-btn icon x-small color="rgba(26,128,255,1)">
                <v-icon x-small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </div>
          <v-img
            class="flex-shrink-1"
            min-width="30"
            max-width="75"
            src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/start_using_icon%402x.png"
          ></v-img>
        </div>

        <div class="d-flex justify-space-between align-center" style="width:100%">
          <p class="subtitle-1" style="color:#1A80FF;">免费注册</p>
          <p class="subtitle-1" style="color:#1A80FF;">上传舱容表</p>
          <p class="subtitle-1" style="color:#1A80FF;">平台审核</p>
          <p class="subtitle-1" style="color:#1A80FF;">开始使用</p>
        </div>
        <!-- <v-row no-gutters justify="space-between">
          <v-col cols="12" md="auto">
            <div style="cursor:pointer;" @click="$router.push('/registered')">
              <v-img
                width="80"
                class="mx-auto mb-6"
                src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/free_registration_icon%402x.png"
              ></v-img>
              <p class="headline" style="color:#1A80FF;">免费注册</p>
            </div>
          </v-col>
          <v-col cols="auto" style="height:80px;" class="d-flex align-center">
            <v-item v-for="n in length" :key="`btn-${n}`" class="mx-auto">
              <v-btn icon x-small color="rgba(26,128,255,1)">
                <v-icon x-small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-col>
          <v-col cols="12" md="auto">
            <v-img
              width="80"
              class="mx-auto mb-6"
              src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/upload_icon%402x.png"
            ></v-img>
            <p class="headline" style="color:#1A80FF;">上传舱容表</p>
          </v-col>
          <v-col cols="auto" style="height:80px;" class="d-flex align-center">
            <v-item v-for="n in length" :key="`btn-${n}`">
              <v-btn icon x-small color="rgba(26,128,255,1)">
                <v-icon x-small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-col>
          <v-col cols="12" md="auto">
            <v-img
              width="80"
              class="mx-auto mb-6"
              src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/platform_audit_icon%402x.png"
            ></v-img>
            <p class="headline" style="color:#1A80FF;">平台审核</p>
          </v-col>
          <v-col cols="auto" style="height:80px;" class="d-flex align-center">
            <v-item v-for="n in length" :key="`btn-${n}`">
              <v-btn icon x-small color="rgba(26,128,255,1)">
                <v-icon x-small>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-col>
          <v-col cols="12" md="auto">
            <v-img
              width="80"
              class="mx-auto mb-6"
              src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/start_using_icon%402x.png"
            ></v-img>
            <p class="headline" style="color:#1A80FF;">开始使用</p>
          </v-col>
        </v-row>-->
      </div>
    </v-card>

    <v-card
      id="card5"
      tile
      :height="bannerHeigth"
      class="mx-auto white--text d-flex flex-column justify-center"
      flat
      img="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/business_entrance_bg%402x.png"
    >
      <v-row
        class="card5"
        style="margin: 0 auto;"
        no-gutters
        justify="space-between"
        align="center"
      >
        <v-col md="4" xs="6" cols="6">
          <div
            @click="$router.push('/about')"
            class="d-flex justify-center align-center flex-column mx-auto plate py-md-12"
            style="border:1px solid rgba(255,255,255,1);width:296px;height:410px;border-radius:16px;cursor:pointer;"
          >
            <v-img
              class="mb-12 mx-auto;"
              max-width="120"
              max-height="120"
              src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/liquid _cargo_metering_icon%402x.png"
            ></v-img>
            <v-btn
              text
              style="font-size:28px;font-weight:400;"
              max-width="180"
              rounded
              color="#fff"
            >液货计量</v-btn>
          </div>
        </v-col>
        <v-col md="4" xs="6" cols="6">
          <div
            @click="$router.push('/bulkload')"
            class="d-flex justify-center align-center flex-column mx-auto plate py-md-12"
            style="border:1px solid rgba(255,255,255,1);width:296px;height:410px;border-radius:16px;cursor:pointer;"
          >
            <v-img
              class="mb-12"
              max-width="120"
              max-height="120"
              src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/bulk_weight_icon%402x.png"
            ></v-img>
            <v-btn
              text
              style="font-size:28px;font-weight:400;"
              max-width="180"
              rounded
              color="#fff"
            >散货计重</v-btn>
          </div>
        </v-col>
        <v-col md="4" xs="6" cols="6">
          <div
            @click="$router.push('/Inquire')"
            class="d-flex justify-center align-center flex-column mx-auto plate py-md-12"
            style="border:1px solid rgba(255,255,255,1);width:296px;height:410px;border-radius:16px;cursor:pointer;"
          >
            <v-img
              class="mb-12"
              max-width="120"
              max-height="120"
              src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/data_query_icon%402x.png"
            ></v-img>
            <v-btn
              text
              style="font-size:28px;font-weight:400;"
              max-width="180"
              rounded
              color="#fff"
            >数据查询</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<style>
@media screen {
  @media (min-width: 768px) {
    .card5 {
      max-width: 750px;
    }
  }
  @media (min-width: 1200px) {
    .card5 {
      min-width: 1200px;
    }
  }
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px !important;
}

.plate:hover {
  background: hsla(0, 0%, 100%, 0.25) border-box;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.3) inset,
    0 0.5em 1em rgba(0, 0, 0, 0.6);
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, 0.3);
}
</style>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data: () => ({
    bannerHeigth: 0,
    banner: 0,
    cardThreeLength: 6,
    length: 6,
    autorun: true,
    index: 1,
    bannerArr: [
      {
        id: 0,
        title: "速度快",
        p: "仅需几分钟即可完成计量计重出具报告，大幅提高工作效率!",
        src:
          "https://marcoload.oss-cn-qingdao.aliyuncs.com/img/fast_image_one%402x.png",
        width: "709",
        height: "634",
        titleHeight: "365px"
      },
      {
        id: 1,
        title: "结果准",
        p:
          "无需计算！只要输入吃水等观测值即可自动完成工作，精度可达小数点后6位！",
        src:
          "https://marcoload.oss-cn-qingdao.aliyuncs.com/img/accurate_two%402x.png",
        width: "645",
        height: "496",
        titleHeight: "365px"
      },
      {
        id: 2,
        title: "数据全",
        p:
          "内置船舶资料，作业数据提供照片存证，提供经验常数（底量）、运载能力、亏舱率、装卸效率等全方位大数据分析功能",
        src:
          "https://marcoload.oss-cn-qingdao.aliyuncs.com/img/whole_three%402x.png",
        width: "557",
        height: "478",
        titleHeight: "600px"
      },
      {
        id: 3,
        title: "传输易",
        p:
          "自动生成PDF格式计量报告，既可打印输出，也可通过QQ、微信等分享传输！",
        src:
          "https://marcoload.oss-cn-qingdao.aliyuncs.com/img/easy_four%402x.png",
        width: "684",
        height: "476",
        titleHeight: "424px"
      },
      {
        id: 4,
        title: "防伪造",
        p: "船舶资料、计量报告和全部作业数据均采用区块链储存，防伪造防篡改！",
        src:
          "https://marcoload.oss-cn-qingdao.aliyuncs.com/img/prevention_five%402x.png",
        width: "661",
        height: "475",
        titleHeight: "387px"
      },
      {
        id: 5,
        title: "控成本",
        p:
          "标准化作业流程和强大的计算能力，新手也能迅速具备上岗作业能力，大幅降低人员成本！",
        src:
          "https://marcoload.oss-cn-qingdao.aliyuncs.com/img/control_six%402x.png",
        width: "739",
        height: "441",
        titleHeight: "409px"
      }
    ]
  }),
  created() {
    this.getHeight();
    setInterval(() => {
      if (!this.autorun) return;
      if (++this.banner >= this.length) this.banner = 0;
    }, 6000);
  },

  mounted() {
    this._isMobile();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag) {
        this.cardThreeLength = 3;
      }
      // return flag;
    },
    getHeight() {
      this.bannerHeigth = window.innerHeight;
    }
  }
};
</script>

import axios from 'axios';
import qs from 'qs';

axios.defaults.timeout = 5000;

// axios.defaults.headers = {
//     "content-type": 'application/x-www-form-urlencoded;charset=UTF-8'
// }
axios.defaults.baseURL = process.env.VUE_APP_SECRET; //正式
// axios.defaults.baseURL = 'https://wxshiptest.xzitc.com/'//测试
// axios.defaults.baseURL = 'https://api.ciplat.com/'//开发环境下正式环境
// axios.defaults.baseURL = 'http://ship.com/'//本地
// axios.defaults.baseURL = 'http://shipc.com/'//本地

//http request 拦截器
// axios.interceptors.request.use(s
//     config => {
//         // config.data = qs.stringify(config.data);
//         // config.headers = {
//         //     "Access-Control-Allow-Origin": "http://10.0.0.103:8080/",
//         //     "Access-Control-Allow-Credentials": "true",
//         // }
//         // return config;
//     },
//     error => {
//         return Promise.reject(err);
//     }
// );

//响应拦截器即异常处理
axios.interceptors.response.use(response => {
  return response
}, err => {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        console.log('错误请求')
        break;
      case 401:
        console.log('未授权，请重新登录')
        break;
      case 403:
        console.log('拒绝访问')
        break;
      case 404:
        console.log('请求错误,未找到该资源')
        break;
      case 405:
        console.log('请求方法未允许')
        break;
      case 408:
        console.log('请求超时')
        break;
      case 500:
        console.log('服务器端出错')
        break;
      case 501:
        console.log('网络未实现')
        break;
      case 502:
        console.log('网络错误')
        break;
      case 503:
        console.log('服务不可用')
        break;
      case 504:
        console.log('网络超时')
        break;
      case 505:
        console.log('http版本不支持该请求')
        break;
      default:
        console.log(`连接错误${err.response.status}`)
    }
  } else {
    console.log('连接到服务器失败')
  }
  return Promise.resolve(err.response)
})


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
    },
      {
        timeout: 1000 * 30
      }).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err)
      })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post (url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, qs.stringify(data), {
      timeout: 1000 * 30
    })
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
* 封装patch请求
* @param url
* @param data
* @returns {Promise}
*/

export function patch (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
* 封装put请求
* @param url
* @param data
* @returns {Promise}
*/

export function put (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

export function postFormData (url, params) {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        'Content-Type': 'multipart/form-data'// ;boundary=----WebKitFormBoundaryQ6d2Qh69dv9wad2u
      },
      transformRequest: [function (data) { // 在请求之前对data传参进行格式转换
        const formData = new FormData()
        Object.keys(data).forEach(key => {
          formData.append(key, data[key])
        })
        return formData
      }],
      url,
      method: 'post',
      data: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}


/**
* 下面是获取数据的接口
*/
/** 
* 测试接口
* 名称：exam
* 参数：paramObj/null
* 方式：fetch/post/patch/put
*/
export const server = {
  exam: function (paramObj) {
    return fetch('index.php?c=login&a=send_sms', paramObj);
  },
  code: function (paramObj) {
    return post('index.php?c=login&a=check_verify_code', paramObj);
  },
  regist: function (paramObj) {
    return post('index.php?c=login&a=regist', paramObj);
  },

  perfect: function (paramObj) {
    return post('index.php?c=login&a=perfect', paramObj);
  },
  // 登录
  login: function (paramObj) {
    return post('index.php?c=login&a=login', paramObj);
  },
  // 判断是否登录
  checkStatus: function (paramObj) {
    return fetch('index.php?c=Login&a=check_status', paramObj);
  },
  // 比对公司名，查看是否需要认领公司
  check_firm_name: function (paramObj) {
    return post('index.php?c=login&a=check_firm_name', paramObj);
  },
  //查询作业列表
  Liquid: function (paramObj) {
    return post('index.php?c=Liquid&a=index', paramObj);
  },
  //提交新建作业 
  addresult: function (paramObj) {
    return post('index.php?c=Liquid&a=addresult', paramObj);
  },
  //上传图片
  upImg: function (paramObj) {
    return post('index.php?c=Login&a=upload_ajax', paramObj);
  },
  //认领公司
  claimedFirm: function (paramObj) {
    return post('index.php?c=Login&a=claimed_firm', paramObj);
  },
  //上传文件
  upClaimedFirm: function (paramObj) {
    return postFormData("index.php?c=Upload&a=claimed_file", paramObj);
  },
  //获取全部检验公司
  searchJian: function (paramObj) {
    return fetch('index.php?c=search&a=jian', paramObj);
  },
  //获取全部船舶公司
  searchChuan: function (paramObj) {
    return fetch('index.php?c=search&a=chuan', paramObj);
  },
  //获取检验公司信息详情
  searchJianMsg: function (paramObj) {
    return fetch('index.php?c=search&a=jianmsg', paramObj);
  },
  //获取船舶公司信息详情
  searchShipMsg: function (paramObj) {
    return fetch('index.php?c=search&a=chuanmsg', paramObj);
  },
  //重置通知状态
  reset_status: function (paramObj) {
    return fetch('index.php?c=login&a=reset_status', paramObj);
  },
  // 获取管理员用户公司信息
  getAdministratorMsg: function (paramObj) {
    return fetch('index.php?c=Firm&a=msg', paramObj);
  },
  // 获取管理员液货船信息
  tankerInformation: function (paramObj) {
    return fetch('index.php?c=Ship&a=index', paramObj);
  },
  // 获取管理员散货船信息
  bulkcarrierInformation: function (paramObj) {
    return fetch('index.php?c=ShShip&a=index', paramObj);
  },
  // 获取管理员人员列表
  peopleList: function (paramObj) {
    return fetch('index.php?c=User&a=index', paramObj);
  },
  // 新建用户信息
  newUserInformation: function (paramObj) {
    return post('index.php?c=User&a=add', paramObj);
  },
  // 修改用户信息
  modifyUserInformation: function (paramObj) {
    return post('index.php?c=User&a=edit', paramObj);
  },
  // 查询权限
  queryPermissions: function (paramObj) {
    return post('index.php?c=User&a=configSearchs', paramObj);
  },
  // 修改查询权限
  modifyQueryPermissions: function (paramObj) {
    return post('index.php?c=User&a=searchconfig', paramObj);
  },
  // 操作权限
  operationAuthority: function (paramObj) {
    return post('index.php?c=User&a=usermsgs', paramObj);
  },
  // 修改操作权限
  modifyOperationAuthority: function (paramObj) {
    return post('index.php?c=User&a=usermsgs', paramObj);
  },
  // 充值记录
  rechargeRecord: function (paramObj) {
    return fetch('index.php?c=Recharge&a=index', paramObj);
  },
  // 消费记录
  expensesRecord: function (paramObj) {
    return fetch('index.php?c=Consumption&a=index', paramObj);
  },


  //获取全部液货船
  searchYe: function (paramObj) {
    return fetch('index.php?c=search&a=ship', paramObj);
  },
  //获取液货船详情
  searchYeShip: function (paramObj) {
    return fetch('index.php?c=search&a=shipmsg', paramObj);
  },
  //获取液货船高级详情
  searchYeShipGrade: function (paramObj) {
    return fetch('index.php?c=search&a=msgship', paramObj);
  },
  // 液货船-获取所有作业id
  getYeWorkId: function (paramObj) {
    return fetch('index.php?c=search&a=results', paramObj);
  },
  //液货船-新建船舶
  newTanker: function (paramObj) {
    return post('index.php?c=Ship&a=addship', paramObj);
  },
  //液货船-修改船舶
  modifyTanker: function (paramObj) {
    return post('index.php?c=Ship&a=editship', paramObj);
  },
  //液货船-船舱管理
  cabinManagement: function (paramObj) {
    return fetch('index.php?c=Cabin&a=index', paramObj);
  },
  //液货船-获取船舱详细信息
  modifyCabin: function (paramObj) {
    return post('index.php?c=Cabin&a=cabinmsgs', paramObj);
  },
  //液货船-获取作业信息详情
  liquidMsg: function (paramObj) {
    return fetch('index.php?c=liquid&a=msg', paramObj);
  },
  //液货船-获取生成报表所需要的所有信息
  liquidPdf: function (paramObj) {
    return post('index.php?c=liquid&a=get_pdf', paramObj);
  },
  //液货船-获取修改作业时需要用到的历史个性化数据
  liquidStart: function (paramObj) {
    return post('index.php?c=liquid&a=is_start', paramObj);
  },
  //液货船-修改作业
  liquidresult: function (paramObj) {
    return post('index.php?c=liquid&a=editresult', paramObj);
  },


  //获取全部散货船
  searchShan: function (paramObj) {
    return fetch('index.php?c=search&a=sh_ship', paramObj);
  },
  //获取散货船详情
  searchShanShip: function (paramObj) {
    return fetch('index.php?c=search&a=sh_shipmsg', paramObj);
  },
  //获取散货船高级详情
  searchShanShipGrade: function (paramObj) {
    return fetch('index.php?c=search&a=sh_msgship', paramObj);
  },
  // 散货船-获取所有作业id
  getSanWorkId: function (paramObj) {
    return fetch('index.php?c=search&a=sh_result', paramObj);
  },
  //散货船-新建船舶
  newShipTanker: function (paramObj) {
    return post('index.php?c=ShShip&a=addship', paramObj);
  },
  //散货船-上传新建散货船审核图片
  createShImg: function (paramObj) {
    return post('index.php?c=Upload&a=create_sh_img', paramObj);
  },
  //散货船-上传修改散货船审核图片
  uploadShImg: function (paramObj) {
    return post('index.php?c=Upload&a=upload_sh_img', paramObj);
  },
  //散货船-修改散货船舶信息
  editShip: function (paramObj) {
    return post('index.php?c=ShShip&a=editship', paramObj);
  },
  //散货船-获取散货船作业列表
  bulkindex: function (paramObj) {
    return post('index.php?c=Bulk&a=index', paramObj);
  },
  //散货船-添加作业
  bulkaddresult: function (paramObj) {
    return post('index.php?c=Bulk&a=addresult', paramObj);
  },
  //散货船-获取作业信息详情
  bulkmsg: function (paramObj) {
    return fetch('index.php?c=Bulk&a=msg', paramObj);
  },
  //散货船-获取生成报表所需要的所有信息
  baobiao: function (paramObj) {
    return fetch('index.php?c=Bulk&a=baobiao', paramObj);
  },
  //散货船-获取生成报表所需要的所有信息
  bulkPdf: function (paramObj) {
    return post('index.php?c=Bulk&a=get_pdf', paramObj);
  },
  //散货船-获取修改作业时需要用到的历史个性化数据
  isStart: function (paramObj) {
    return post('index.php?c=Bulk&a=is_start', paramObj);
  },
  //散货船-修改作业
  editresult: function (paramObj) {
    return post('index.php?c=bulk&a=editresult', paramObj);
  },


  //公司信息数据————图表
  firmCount: function (paramObj) {
    return post('index.php?c=Search&a=firm_count', paramObj);
  },
  //一键已读
  allReaded: function (paramObj) {
    return post('index.php?c=Notice&a=allReaded', paramObj);
  },
  //获取站内信详细信息
  msgNotice: function (paramObj) {
    return post('index.php?c=Notice&a=msg', paramObj);
  },
  //获取/刷新用户的未读信息个数
  unreadNum: function (paramObj) {
    return post('index.php?c=Notice&a=unreadNum', paramObj);
  },
  //获取站内信列表
  indexNotice: function (paramObj) {
    return post('index.php?c=Notice&a=index', paramObj);
  },
  //获取业务比例
  searchFirm: function (paramObj) {
    return post('index.php?c=search&a=count_ship_firm_percent', paramObj);
  },
  //获取个人作业
  searchStaff: function (paramObj) {
    return post('index.php?c=search&a=count_staff_percent', paramObj);
  },
  //获取装/卸货分析
  searchLoading: function (paramObj) {
    return post('index.php?c=search&a=count_loading_unloading', paramObj);
  },
}
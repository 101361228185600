<template>
  <v-app>
    <!-- :absolute="this.$route.name=='Home'?false:true"
    :fixed="this.$route.name=='Home'?true:false"-->

    <!-- class="d-none d-md-flex" -->
    <v-app-bar flat fixed :color="this.$route.name=='Home'?appbarBack:'#fff'" class="pa-0 mx-auto"
      v-if="$route.meta.showTab">
      <v-card width="1200" flat tile :color="this.$route.name=='Home'?appbarBack:'#fff'"
        class="d-flex pa-0 align-center mx-auto">
        <v-img max-width="200" :src="this.$route.name=='Home'?logo:logoB" @click="drawer = true"></v-img>

        <v-spacer></v-spacer>

        <v-btn text :active-class="this.$route.name=='Home'?activeW:'b'"
          :color="this.$route.name=='Home'?appbarColor:'#bbd1ff'" style="color:#bbd1ff" to="/"
          class="body-1 d-none d-sm-flex">首页</v-btn>

        <v-btn text class="appBarBtn body-1 d-none d-sm-flex" to="/about"
          :active-class="this.$route.name=='Home'?activeW:'b'" :color="this.$route.name=='Home'?appbarColor:'#bbd1ff'"
          style="color:#bbd1ff">液货计量</v-btn>

        <v-btn text exact class="appBarBtn body-1 d-none d-sm-flex" to="/bulkload"
          :active-class="this.$route.name=='Home'?activeW:'b'" :color="this.$route.name=='Home'?appbarColor:'#bbd1ff'"
          style="color:#bbd1ff">散货计重</v-btn>

        <v-btn text class="appBarBtn body-1 d-none d-sm-flex" to="/inquire"
          :active-class="this.$route.name=='Home'?activeW:'b'" :color="this.$route.name=='Home'?appbarColor:'#bbd1ff'"
          style="color:#bbd1ff">数据查询</v-btn>

        <v-btn text class="appBarBtn body-1 d-none d-sm-flex" @click="goPage('./ShipCompany.html')"
          :active-class="this.$route.name=='Home'?activeW:'b'" :color="this.$route.name=='Home'?appbarColor:'#bbd1ff'"
          style="color:#bbd1ff">统计分析</v-btn>

        <v-btn text to="/Mobile" class="appBarBtn body-1 d-none d-sm-flex"
          :active-class="this.$route.name=='Home'?activeW:'b'" :color="this.$route.name=='Home'?appbarColor:'#bbd1ff'"
          style="color:#bbd1ff">手机终端</v-btn>

        <v-btn text class="appBarBtn body-1 d-none d-sm-flex" v-if="code==25565"
          :active-class="this.$route.name=='Home'?activeW:'b'" :color="this.$route.name=='Home'?appbarColor:'#bbd1ff'"
          style="color:#bbd1ff" to="/login">登录</v-btn>

        <v-menu offset-y v-if="code==1">
          <template v-slot:activator="{ on }">
            <!-- <v-btn
              style="color:#bbd1ff"
              text v-show="unreadnum==0"
              class="appBarBtn body-1 d-none d-sm-flex"
              v-on="on"
            >
              个人中心
            </v-btn> -->
            <v-btn style="color:#bbd1ff" text class="appBarBtn body-1 d-none d-sm-flex" v-on="on">
              <v-badge color="red" :content="unreadnum" :value="unreadnum">
                个人中心
              </v-badge>
            </v-btn>
          </template>
          <v-list style="background: #fff;">
            <!-- <v-list-item 
              v-for="(item, index) in items" 
              :key="index" @click="out(item.title)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item> -->
            <v-list-item v-for="(item, index) in items" :key="index" @click="out(item.title)">
              <!-- <v-list-item-title>{{ item.title }}</v-list-item-title> -->
              <v-list-item-title style="background-color: #fff !important;">
                {{ item.title }}
                <v-badge v-if="item.title=='站内信' && unreadnum!=0" color="red" dot>
                </v-badge>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card>

      <!-- <div
        style="width:100%;height:1px;position: absolute;top:64px;background:rgba(255,255,255,0.5);"
      ></div>-->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary color="#000" dark>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item to="/about">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-content>
      <router-view />
      <tips></tips>
    </v-content>

    <v-footer id="card6" padless color="#414141" v-if="$route.meta.showTab">
      <!-- <v-col class="text-center" cols="12">
        <p class="d-inline caption" style="color:#817F7F;">版权所有</p>
        <a
          class="d-inline white--text caption"
          href="http://www.xzitc.com"
          target="_blank"
        >&nbsp;南京携众信息科技有限公司</a>
        <p class="d-inline caption" style="color:#817F7F;">&nbsp;@2018-2018</p>
        <a
          class="d-inline white--text caption"
          href="http://beian.miit.gov.cn/"
          target="_blank"
        >&nbsp;&nbsp;&nbsp;苏ICP备07023176号-2</a>
      </v-col> -->
      <v-card width="1200" tile elevation="0" color="#414141" class="mx-auto footer-card">
        <v-container fluid>
          <v-row class="mx-auto justify-space-between">
            <v-col md="2" sm="6" xs="12" cols="12" class="text-center">
              <strong class="subheading white--text title text-center" style="text-align:center">微信公众号</strong>
              <v-divider color="#fff" class="mt-2 mb-8"></v-divider>
              <v-img class="mx-auto" width="125" src="./assets/images/wx_img.png"></v-img>
            </v-col>
            <v-col md="2" sm="6" xs="12" cols="12" class="text-center">
              <strong class="subheading white--text title text-center" style="text-align:center">平台QQ群</strong>
              <v-divider color="#fff" class="mt-2 mb-8"></v-divider>
              <v-img class="mx-auto" width="125" src="./assets/images/qq_Img.png"></v-img>
            </v-col>
            <v-col md="3" sm="6" xs="12" cols="12" class="text-lg-left">
              <strong class="subheading white--text title">CiPlat平台</strong>
              <v-divider color="#fff" class="mt-2 mb-8"></v-divider>
              <div class="d-flex justify-center justify-md-start justify-sm-center">
                <div class="d-flex flex-column mr-8">
                  <router-link class="white--text" to="PlatformIntroduction">平台介绍</router-link>

                  <router-link class="white--text" :to="{ name: 'PlatformIntroduction', query: { path: 'embodiment' }}">
                    价值体现</router-link>
                  <a class="white--text">应用场景</a>
                  <a class="white--text">功能演示</a>
                </div>
                <div>
                  <a style="text-decoration: underline;" class="white--text"
                    @click="goPage('https://ciplat.baklib.com/')">
                    使用指南
                  </a>
                </div>
              </div>
            </v-col>
            <v-col md="4" sm="6" xs="12" cols="12" class="text-lg-left">
              <strong class="subheading white--text title">联系我们</strong>
              <v-divider color="#fff" class="mt-2 mb-8"></v-divider>
              <div class="d-flex text-left justify-center justify-md-start justify-sm-center">
                <div class="d-flex flex-column mr-8">
                  <p class="white--text">
                    <v-icon color="#fff" class="pr-2" small>mdi-phone</v-icon>025-86608700
                  </p>
                  <p class="white--text">
                    <v-icon color="#fff" class="pr-2" small>mdi-map-marker</v-icon>南京市鼓楼区幕府东路199号A38幢
                  </p>
                  <p class="white--text">
                    <v-icon color="#fff" class="pr-2" small>mdi-qqchat</v-icon>1271880510
                  </p>
                </div>
                <!-- <div class="d-flex ">
                  <p class="white--text mr-4">QQ群:</p>
                  <v-img class="mx-auto" width="125" src="./assets/images/qq_Img.png"></v-img>
                </div>-->
              </div>
            </v-col>
          </v-row>
          <p class="body-2 text-center white--text mt-6">
            版权所有
            <a class="white--text" href="http://www.xzitc.com" target="_blank">&nbsp;南京携众信息科技有限公司</a>
            @2018-2020
            <a class="white--text" href="http://beian.miit.gov.cn/"
              target="_blank">&nbsp;&nbsp;&nbsp;苏ICP备07023176号-2</a>
          </p>
        </v-container>
      </v-card>
    </v-footer>
  </v-app>
</template>

<style lang="less">
@media screen {
  @media (min-width: 768px) {
    .footer-card {
      max-width: 750px;
    }
  }
  @media (min-width: 1200px) {
    .footer-card {
      max-width: 1200px;
    }
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.appBarBtn {
  color: rgba(255, 255, 255, 0.5);
}

.v-btn:before {
  background-color: transparent !important;
}

.w {
  color: #fff !important;
}

.b {
  color: #919191 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.theme--light.v-list {
  margin-bottom: 0px !important;
}
</style>


<script>
import tips from "@/components/common/tips";
import logoB from "./assets/logoB.png";
import logoW from "./assets/logoW.png";
export default {
  name: "App",
  components: { tips },
  data () {
    return {
      infonList: false,
      activeW: "w",
      logoB,
      logoW,
      code: 25565,
      length: 6,
      banner: 0,
      index: 1,
      drawer: false,
      timeshow: "",
      appbarBack: "transparent",
      appbarColor: "#9da4b5",
      unreadnum: 0,
      logo: require("./assets/logoW.png"),
      items: [{ title: "管理员设置" }, { title: "站内信" }, { title: "退出登录" }]
    }
  },
  watch: {
    $route (to, from) {
      localStorage.setItem("path", to.path);
      if (to.path == "/") {
        this.index = 1;
      }
      this.infonList = JSON.parse(localStorage.getItem("info"));
      this.code = localStorage.getItem("code") ? localStorage.getItem("code") : '25565';
      if (this.infonList) {
        if (this.infonList.pid != "0") {
          this.items = [{ title: "退出登录" }];
        }
      }
    }
  },
  created () {
    this.$server.checkStatus().then(data => {
      this.code = data.code ? data.code : '25565';
      localStorage.setItem("code", this.code);
      // this.code = data.code;
    });
    this.$server.unreadNum().then(data => {
      this.unreadnum = data.num;
      // console.log(this.unreadnum);
    });
    this.infonList = JSON.parse(localStorage.getItem("info"));
    // this.code = localStorage.getItem("code")?localStorage.getItem("code"):25565;
    // this.code = localStorage.getItem("code");
    if (this.infonList) {
      if (this.infonList.pid != "0") {
        this.items = [{ title: "退出登录" }];
      }
    }
  },
  mounted () {
    document.addEventListener(
      "mousewheel",
      this.debounce(this.handleScroll, 300),
      { passive: false }) ||
      document.addEventListener(
        "DOMMouseScroll",
        this.debounce(this.handleScroll, 300),
        { passive: false });
    this.timer = setInterval(this.get, 1000);
    this.timeshow = setInterval(this.setTimer, 10000);
  },
  methods: {
    setTimer () {
      this.$server.unreadNum().then(data => {
        this.unreadnum = data.num;
        // console.log(this.unreadnum);
      });
    },
    debounce (func, wait) {
      let timeout;
      return function (e) {
        if (localStorage.getItem("path") == '/') {
          e.preventDefault();
        }
        let context = this;
        let args = arguments;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
        return false;
      }
    },
    handleScroll (e) {
      let direction = e.deltaY > 0 ? "down" : "up";
      let scrollTop = document.documentElement.scrollTop;
      if (this.$route.path == "/") {
        if (direction == "down") {
          if (this.index < 6) {
            this.index++;
          } else {
            this.index = 6;
          }
          this.$vuetify.goTo("#card" + this.index);
        } else {
          if (this.index > 1) {
            this.index--;
          } else {
            this.index = 1;
          }
          this.$vuetify.goTo("#card" + this.index);
        }
        if (this.index == 2 || this.index == 4) {
          this.activeW = "b";
          this.appbarColor = "rgba(28,124,255,0.4);";
          this.appbarBack = "#fff";
          this.logo = logoB;
        } else {
          this.activeW = "w";
          this.appbarColor = "#9da4b5";
          this.appbarBack = "transparent";
          this.logo = logoW;
        }
      }
    },
    //退出登录
    out (e) {
      switch (e) {
        case "管理员设置":
          this.$router.push({ name: "Administrator" });
          break;
        case "站内信":
          this.$router.push({ name: "Stationletter" });
          break;
        case "退出登录":
          this.code = 25565;
          localStorage.setItem("code", '25565');
          localStorage.removeItem("info");
          this.$router.push({
            path: '/',
          })
          break;
      }
    },
    // 跳转使用指南
    goPage (url) {
      if (this.code == "1" && this.infonList != "") {
        window.open(url + "?firmid=" + this.infonList.firmid, '_blank')
        console.log("firmid=", this.infonList.firmid)
      } else {
        this.$router.push({ path: '/login' })
      }
    }
  }
};
</script>


<template>
  <v-container fluid class="pa-0" style="height:100%;">
    <v-card height="100%" class="pb-0" flat tile>
      <v-row no-gutters justify="center" align="center" style="height:100%;">
        <v-img
          class="d-none d-md-flex"
          style="box-shadow:0px 0px 35px 0px rgba(48,108,255,0.08);"
          max-width="670"
          src="https://waddles.oss-cn-shanghai.aliyuncs.com/cargoImage/sign_in_image%402x.png"
        ></v-img>
        <v-card
          width="530"
          max-height="600"
          color="#fff"
          style="box-shadow:0px 0px 35px 0px rgba(48,108,255,0.08);"
        >
          <v-img max-width="170" class="mx-auto my-12" src="../assets/images/login_logo.png"></v-img>
          <v-sheet max-width="410" class="mx-auto">
            <v-text-field color="#0F7AFF" prefix="用户名" append-icon="mdi-account" v-model="title"></v-text-field>
            <v-text-field
              color="#0F7AFF"
              prefix="密 码"
              append-icon="mdi-lock"
              v-model="pwd"
              type="password"
            ></v-text-field>
            <v-btn text class="float-right px-0" style="color: rgba(39,135,255,0.6);">忘记密码</v-btn>
            <v-btn
              width="100%"
              height="50"
              color="#2787FF"
              rounded
              style="font-size: 24px;color:#fff;margin-top: 50px;"
              @click="loginBtn"
            >登录</v-btn>

            <v-sheet
              class="text-center d-flex justify-center align-center"
              style="margin-top: 30px;color: #2787FF;font-size: 18px;font-weight:500;line-height:36px;"
            >
              <v-btn
                height="36px"
                text
                style="color: #2787FF;font-size: 18px;font-weight:500;"
                class="px-0 align-self-center"
                to="/registered"
              >
                没有账号？去注册
              </v-btn>
            </v-sheet>
          </v-sheet>
        </v-card>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    title: "",
    pwd: ""
  }),
  methods: {
    loginBtn() {
      let paramObj = {
        title: this.title,
        pwd: this.pwd
      };
      if (this.title && this.pwd) {
        this.$server.login(paramObj).then(data => {
          if (data.code === 1009) {
            if (data.reg_status == 0) {
              // 完善公司信息
              this.$router.push({
                name: "Registered",
                params: {
                  step: 4,
                  phone: data.content.phone,
                  username: data.content.username
                }
              });
            } else if (data.reg_status == 1) {
              // 公司认领审核ing
              this.$router.push({ name: "Registered", params: { step: 7 } });
            } else if (data.reg_status == 3) {
              // 认领失败
              this.$router.push({
                name: "Registered",
                params: {
                  step: 9,
                  phone: data.content.phone,
                  username: data.content.username
                }
              });
            }
          } else if (data.code === 1) {
            this.$router.push("/");
            const info = {
              uid: data.content.id,
              name: data.content.username,
              // 公司id
              firmid: data.content.firmid,
              // 权限
              pid: data.content.pid,
              phone: data.content.phone,
              // 公司类型
              firmname: data.content.firmname,
              // 公司类型
              firmtype: data.content.firmtype
            };
            localStorage.setItem("info", JSON.stringify(info));
            localStorage.setItem("code", '1');
          } else {
            this.bus.$emit("tips", {
              show: true,
              title: data.error
            });
          }
        });
      } else {
        this.bus.$emit("tips", {
          show: true,
          title: "用户名或密码不能为空"
        });
      }
    }
  }
};
</script>

<style>
.v-application--is-ltr .v-text-field__prefix {
  display: inline-block;
  width: 143px;
  /* padding-right: 4px; */
  text-align: justify;
  text-align-last: justify;
  font-size: 22px;
}

.theme--light.v-input {
  color: #2787ff;
}

.theme--light.v-icon {
  color: #2787ff;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #2787ff;
}

.theme--light.v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border-color: #2787ff;
}
</style>
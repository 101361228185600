import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login.vue'
import Registered from '../views/registered.vue'
import goTo from 'vuetify/es5/services/goto'
import tips from '../components/common/tips.vue'
import qs from 'qs'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      showTab: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      showTab: true,
      requireAuth: true
    }
  },
  {
    path: '/bulkload',
    name: 'bulkload',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/bulkload.vue'),
    meta: {
      showTab: true,
      requireAuth: true
    }
  },
  {
    path: '/ab_details',
    name: 'ab_details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ab_details.vue'),
    meta: {
      showTab: true,
      requireAuth: true
    }
  },
  {
    path: '/bl_details',
    name: 'bl_details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/bl_details.vue'),
    meta: {
      showTab: true,
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/registered',
    name: 'Registered',
    component: Registered
  }, 
  {
    path: '/mobile',
    name: 'Mobile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Mobile.vue'),
    meta: {
      showTab: true,
    }
  },
  {
    path: '/inquire',
    name: 'Inquire',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Inquire.vue'),
    meta: {
      showTab: true,
      requireAuth: true
    }
  },
  {
    path: '/Stationletter',
    name: 'Stationletter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Stationletter.vue'),
    meta: {
      showTab: true,
      requireAuth: true
    }
  },
  {
    path: '/companyDetails',
    name: 'CompanyDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CompanyDetails.vue'),
    meta: {
      showTab: true,
    }
  },
  {
    path: '/companyDetails/CompanyInformationData',
    name: 'CompanyInformationData',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CompanyInformationData.vue'),
    meta: {
      showTab: true,
    }
  },
  {
    path: '/shipDetails',
    name: 'ShipDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ShipDetails.vue'),
    meta: {
      showTab: true,
    }
  },
  {
    path: '/shipJobDetails',
    name: 'ShipJobDetails',
    component: () => import('../views/ShipJobDetails.vue'),
    meta: {
      showTab: true,
    }
  }, {
    path: '/PlatformIntroduction',
    name: 'PlatformIntroduction',
    component: () => import('../views/PlatformIntroduction.vue'),
    meta: {
      showTab: true,
    }
  },
  {
    path: '/Administrator',
    name: 'Administrator',
    component: () => import('../views/Administrator.vue'),
    redirect: "/Administrator/CompanyInformation",
    meta: {
      showTab: false, requireAuth: true
    },
    children: [{
      path: '/Administrator/CompanyInformation',
      name: 'CompanyInformation',
      component: () => import('../views/Administrator/CompanyInformation.vue'),
      meta: {
        showTab: false,
      }
    }, {
      path: '/Administrator/TankerManagement',
      name: 'TankerManagement',
      component: () => import('../views/Administrator/TankerManagement.vue'),
      meta: {
        showTab: false,
      },
    },
    {
      path: '/Administrator/CabinManagement',
      name: 'CabinManagement',
      component: () => import('../views/Administrator/CabinManagement.vue'),
      meta: {
        showTab: false,
      },
    },
    {
      path: '/Administrator/PeopleManagement',
      name: 'PeopleManagement',
      component: () => import('../views/Administrator/PeopleManagement.vue'),
      meta: {
        showTab: false,
      }
    },
    {
      path: '/Administrator/RechargeRecord',
      name: 'RechargeRecord',
      component: () => import('../views/Administrator/RechargeRecord.vue'),
      meta: {
        showTab: false,
      }
    },
    {
      path: '/Administrator/ExpensesRecord',
      name: 'ExpensesRecord',
      component: () => import('../views/Administrator/ExpensesRecord.vue'),
      meta: {
        showTab: false,
      }
    },
    {
      path: '/Administrator/BulkCarrierManagement',
      name: 'BulkCarrierManagement',
      component: () => import('../views/Administrator/BulkCarrierManagement.vue'),
      meta: {
        showTab: false,
      }
    }
    ],
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0
    // if (to.hash) {
    //   scrollTo = to.hash
    // } else if (savedPosition) {
    //   scrollTo = savedPosition.y
    // }
    if (to.query.path) {
      scrollTo = '#' + to.query.path
    }

    return goTo(scrollTo)
  },
})

export default router
